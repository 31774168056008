<template>
  <v-dialog data-app v-model="opened" max-width="700px" @click:outside="onClose">
    <v-card :loading="loading">
      <v-icon class="btn-close-modal" @click="onClose" size="36">mdi-close</v-icon>
      <v-card-title>{{ promo.id ? promo.title : 'Новый промокод' }}
<!--        <v-chip v-if="!promo.isActive" class="ml-4" color="#eee" style="font-size: 14px; font-weight: normal">Не активен</v-chip>-->
      </v-card-title>
      <v-card-text>
        <!--        'title', 'code',
                    'comment',
                    'discount_sum', 'discount_percent',
                    'date_from', 'date_to',
                    -->
        <v-form ref="form">
          <v-row>
            <v-col cols="12"
                   sm="3">
              <v-subheader>Код <span style="color: red">*</span></v-subheader>
              <v-text-field v-model="promo.code" outlined required
                            :rules="[v=>!!v || 'Укажите код']"
                            :disabled="!promo.isEditable"
                            validate-on-blur/>
            </v-col>
            <v-col cols="12" sm="9">
              <v-subheader>Заголовок <span style="color: red">*</span></v-subheader>
              <v-text-field v-model="promo.title" outlined required
                            :rules="[v=>!!v || 'Укажите заголовок']"
                            :disabled="!promo.isEditable"
                            validate-on-blur/>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" sm="6" cols="12">
              <v-subheader>Комментарий</v-subheader>
              <v-textarea v-model="promo.comment" outlined rows="4" no-resize :disabled="!promo.isEditable"/>
            </v-col>
            <v-col class="py-0" sm="6" cols="12">
              <v-subheader>Размер скидки<span style="color: red">*</span></v-subheader>
              <v-radio-group v-model="promo.discount_type"
                             @change="$refs.form.resetValidation()"
                             :disabled="!promo.isEditable"
                             :rules="[v=> (!!(promo.discount_sum && promo.discount_type === 'sum')
                            || !!promo.discount_percent && promo.discount_type === 'percent') || 'Укажите размер скидки']">
                <v-row style="height: 40px">
                  <v-col cols="7" class="pb-0 d-flex align-end">
                    <v-radio label="Сумма" value="sum"/>
                  </v-col>
                  <v-col class="pb-0">
                    <v-text-field v-model="promo.discount_sum" outlined hide-details
                                  v-if="promo.discount_type === 'sum'"
                                  :disabled="!promo.isEditable"
                                  @input="$refs.form.validate()">
                      <template #append>{{ $store.state.user.city.country.currency }}</template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row style="height: 40px">
                  <v-col cols="7" class="pb-0 d-flex align-end">
                    <v-radio label="Процент" value="percent"/>
                  </v-col>
                  <v-col class="pb-0">
                    <v-text-field v-model="promo.discount_percent" v-mask="'##'" outlined hide-details
                                  :disabled="!promo.isEditable"
                                  v-if="promo.discount_type === 'percent'"
                                  @input="$refs.form.validate()">
                      <template #append>%</template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="6" cols="12">
              <v-row>
                <v-col class="pa-0">
                  <v-subheader>Ограничения</v-subheader>
                  <v-select v-model="promo.restriction" :items="restrictions" item-value="value" item-text="label" outlined
                            placeholder="Выберите один из вариантов" @change="onChangeRestrictions"
                            :disabled="!promo.isEditable"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="flex-column align-start pa-0">
                  <v-subheader>Период действия</v-subheader>
                  <v-menu
                      ref="menu_datepicker"
                      v-model="menu_datepicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :value="dateRangeText"
                          :disabled="!promo.isEditable"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          clearable
                          @click:clear="promo.dates = []"
                      />
                    </template>
                    <v-date-picker v-model="promo.dates" range/>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="flex-column align-start pb-0" sm="6" cols="12">
              <v-subheader>Область применения</v-subheader>
              <v-row>
                <v-checkbox label="Регулярная уборка" v-model="promo.standart" :true-value="1" :false-value="0"
                            :disabled="!promo.isEditable"/>
              </v-row>
              <v-row>
                <v-checkbox label="Генеральная уборка" v-model="promo.general" :true-value="1" :false-value="0"
                            :disabled="!promo.isEditable"/>
              </v-row>
              <v-row>
                <v-checkbox label="Уборка после ремонта" v-model="promo.remont" :true-value="1" :false-value="0"
                            :disabled="!promo.isEditable"/>
              </v-row>
              <v-row>
                <v-checkbox label="Мытье окон" v-model="promo.window" :true-value="1" :false-value="0" class="mb-4"
                            :disabled="!promo.isEditable"/>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-subheader>Доступность</v-subheader>
              <v-radio-group v-model="promo.city_option" @change="onChangeCityOptions" :disabled="!promo.isEditable"
              :rules="[v=>(v !== 'except_cities' || !!promo.except_cities.length) || 'Выберите города или отметьте «во всех» ']">
                <v-row>
                  <v-col class="pb-0">
                    <v-radio label="Во всех городах" value="all_cities"
                    :disabled="$store.state.user.city.code !== $store.state.user.city.country.main_city"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="5" cols="12" class="pb-0 d-flex align-end">
                    <v-radio label="В выбранных городах" value="cities"/>
                  </v-col>
                  <v-col class="pb-0" sm="7" cols="12">
                    <v-autocomplete v-model="promo.cities" :items="$store.state.user.cities"
                                    item-value="id"
                                    item-text="city"
                                    placeholder="Выберите один или несколько"
                                    :disabled="!promo.isEditable"
                                    outlined v-if="promo.city_option === 'cities'" multiple>

                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>

                  <v-col sm="5" cols="12" class="pb-0 d-flex align-end">
                    <v-radio label="Во всех городах, кроме" value="except_cities"
                             :disabled="$store.state.user.city.code !== $store.state.user.city.country.main_city"/>
                  </v-col>
                  <v-col class="pb-0" sm="7" cols="12">
                    <v-autocomplete v-model="promo.except_cities" :items="$store.state.user.cities"
                                    @change="$refs.form.resetValidation()"
                                    item-value="id"
                                    item-text="city"
                                    placeholder="Выберите один или несколько"
                                    :disabled="!promo.isEditable"
                                    outlined v-if="promo.city_option === 'except_cities'" multiple>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="setPromo" :loading="set_loading" :disabled="!promo.isEditable">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
    <Prompt ref="promo_exist_modal" title="Такой промо уже существует. Открыть его?" @success="openExistPromo"/>
  </v-dialog>
</template>

<script>
import globalMixins from "@/mixins/globalMixins";
import Prompt from "@/containers/Modals/Prompt";
import Date from "@/components/Date";

export default {
  name: "SetPromoModal",
  components: {Date, Prompt},
  mixins: [globalMixins],
  emits: ['on-set'],
  data() {
    return {
      opened: false,
      loading: false,
      set_loading: false,
      promo: {
        dates: [],
        isEditable: true,
        restriction:'',
        discount_type: 'sum',
        city_option: '',
        except_cities: []
      },
      menu_datepicker: false,
      restrictions: [
        {value: '', label: 'Без ограничений'},
        {value: 'first_order', label: 'Первый заказ'},
        {value: 'one_time', label: 'Действителен 1 раз'},
        {value: 'first_mobile', label: 'На первый заказ через приложение'},
      ]
    }
  },
  computed: {
    dateRangeText() {
      if (this.promo.dates) {

        let dates = this.promo.dates.map(date => date ? this.$moment(date).format('DD.MM.YYYY') : '').filter(date=>date);
        return `${dates[0] ? 'с '+dates[0] : ''} ${dates[1] ? 'по '+dates[1] : ''}`;
      }
      return '';
    },
  },
  methods: {
    open(id) {
      this.opened = true;
      this.promo = {
        dates: [],
        isEditable: true,
        restriction: '',
        discount_type: 'sum',
        city_option: '',
        except_cities: []
      };

      setTimeout(()=>{
        this.$refs.form.resetValidation();
      }, 100);
      if (id) this.get(id);
      else {
        if (this.$store.state.user.id) {
          this.promo.cities = [this.$store.state.user.city.id];
          this.promo.city_option = 'cities';
        }
      }
    },
    get(id) {
      this.loading = true;
      this.$store.state.server.request(`promonew/get/${id}`, {}, (data) => {
        this.loading = false;
        this.promo = this.clone(this.prepareDataFromResponse(data.response));
        this.$router.push({query: {promo: id}}).catch(() => {
        });
      }, (data) => {
        this.loading = false;
        this.$root.notify(data.error, 'error');
      })
    },
    setPromo() {
      if (this.$refs.form.validate()) {
        let method = this.promo.id ? `update/${this.promo.id}` : 'create';
        this.set_loading = true;

        this.$store.state.server.request(`promonew/${method}`, this.preparedDataForRequest(), () => {
          this.set_loading = false;
          this.$root.notify(`Промокод успешно ${this.promo.id ? 'сохранен' : 'создан'}`);
          this.$emit('on-set');
          this.onClose();
        }, (data) => {
          this.set_loading = false;
          if (data.exception_type && data.exception_type.includes('promo_exists')) {
            this.$refs.promo_exist_modal.open(data.exception_type.split(' ')[1]);
          }
          this.$root.notify(data.error, 'error');
        })
      }
    },
    preparedDataForRequest() {
      let data = this.clone(this.promo);
      if (data.discount_type === 'sum') {
        data.discount_percent = '';
      } else {
        data.discount_sum = '';
      }
      if (data.city_option === 'all_cities') {
        data.all_cities = 1;
        data.cities = [];
        data.except_cities = [];
      } else if (data.city_option === 'cities') {
        data.all_cities = '';
        data.except_cities = [];
      } else if (data.city_option === 'except_cities') {
        data.cities = [];
        data.all_cities = '';
      }
      if (data.dates.length) {
        if (data.dates[0]) data.date_from = data.dates[0];
        if (data.dates[1]) data.date_to = data.dates[1];
      }
      return data;
    },
    prepareDataFromResponse(response) {
      let data = this.clone(response);
      data.discount_type = +data.discount_sum ? 'sum' : 'percent';
      let restrictionValues = this.restrictions.map(rest => rest.value);
      restrictionValues.forEach(rest => {
        if (rest && +data[rest] === 1) data.restriction = rest;
      })
      data.city_option = '';
      if (data.cities.length) data.city_option = 'cities';
      else if (data.except_cities.length) data.city_option = 'except_cities';
      else if (+data.all_cities) data.city_option = 'all_cities';
      data.dates = [data.date_from, data.date_to].filter(date => date);
      return data;
    },
    onChangeRestrictions() {
      if (this.promo.restriction) {
        this.promo[this.promo.restriction] = 1;
        this.restrictions.forEach(restriction => {
          if (restriction.value && this.promo.restriction !== restriction.value) {
            this.promo[restriction.value] = 0;
          }
        })
      }
    },
    onChangeCityOptions() {
      this.$refs.form.resetValidation();
      if (this.item && this.item.city_option === 'all_cities') this.item.all_cities = 1;
    },
    onClose() {
      this.opened = false;
      this.$router.push({query: {}}).catch(()=>{});
    },
    openExistPromo(id) {
      this.$refs.promo_exist_modal.close();
      this.get(id);
    }
  }
}
</script>

<style scoped>

</style>
