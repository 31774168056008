<template>
<v-dialog data-app v-model="opened" max-width="400px">
  <v-card>
    <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
    <v-card-text>
      <div class="modal-title">{{title}}</div>
    </v-card-text>
    <v-card-actions>
      <v-btn :loading="loading"
          @click="$emit('success', id)" class="flex-grow-1">Да</v-btn>
      <v-btn style="background:  #eee !important;" @click="$emit('decline'); opened = false"
             class="flex-grow-1">Нет</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: "Prompt",
  props: ['title', 'loading'],
  emits: ['success', 'decline'],
  data: ()=>({
    opened: false,
    id: null,
  }),
  methods: {
    open(id) {
      this.id = null;
      this.opened = true;
      this.id = id;
    },
    close() {
      this.opened =false;
    }
  }
}
</script>

<style scoped>
.modal-title {
  padding-top: 24px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
</style>