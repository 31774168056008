var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v("Промокоды (NEW)")]),_c('v-card-actions',{staticStyle:{"padding":"0 16px"}},[_c('v-btn',{on:{"click":function($event){return _vm.openPromoModal()}}},[_vm._v("Создать")])],1),_c('v-card-text',[_c('div',{staticStyle:{"display":"flex","gap":"10px"}},[_c('v-checkbox',{attrs:{"label":"Активные"},model:{value:(_vm.filters.isActive),callback:function ($$v) {_vm.$set(_vm.filters, "isActive", $$v)},expression:"filters.isActive"}}),_c('v-text-field',{attrs:{"outlined":"","placeholder":"Поиск по всем","label":"","append-icon":"mdi-magnify"},on:{"input":function($event){_vm.filters.isActive = false}},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-data-table',{attrs:{"hover":"","items":_vm.filteredItems,"headers":_vm.promoHeader,"item-key":"id","loading":_vm.loading,"search":_vm.filters.search,"footer-props":{'items-per-page-options':[-1]},"no-data-text":"Промокодов нет","loading-text":"Загрузка...","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openPromoModal(item.id)}}},[_c('td',[_vm._v(_vm._s(item.code))]),_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(+item.discount_sum ? item.discount_sum + ' ' + _vm.$store.state.user.city.country.currency : item.discount_percent ? item.discount_percent + '%' : '')+" ")]),_c('td',[(!item.isActive)?_c('v-chip',{staticClass:"ml-4",attrs:{"color":"#eee"}},[_vm._v("Не активен")]):_c('v-chip',{staticClass:"ml-4",attrs:{"color":'#c7ffc3'}},[_vm._v("Активен")])],1),_c('td',[_vm._v(" "+_vm._s(((item.date_from ? 'с ' + _vm.$root.dateToRus(item.date_from) : '') + " " + (item.date_to ? 'по ' + _vm.$root.dateToRus(item.date_to) : '')))+" ")]),_c('td',[(item.all_cities)?[_vm._v("Во всех")]:(item.cities.length)?[_c('v-tooltip',{attrs:{"top":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.cities.map(function (c){ return _vm.$store.state.getCityById(c).city; }).filter(function (c){ return c; }).slice(0,3).join(', '))+" "+_vm._s(item.cities.length>3?("+" + (item.cities.length-3) + " шт."):'')+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.cities.map(function (c){ return _vm.$store.state.getCityById(c).city; }).filter(function (c){ return c; }).join(', '))+" ")])])]:(item.except_cities.length)?[_c('v-tooltip',{attrs:{"top":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" Во всех, кроме: "+_vm._s(item.except_cities.slice(0,3).map(function (c){ return _vm.$store.state.getCityById(c).city; }).filter(function (c){ return c; }).join(', '))+" "+_vm._s(item.except_cities.length>3?("+" + (item.except_cities.length-3) + " шт."):'')+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.except_cities.map(function (c){ return _vm.$store.state.getCityById(c).city; }).filter(function (c){ return c; }).join(', '))+" ")])])]:_vm._e()],2)])]}}])})],1)],1),_c('SetPromoModal',{ref:"set_promo_modal",on:{"on-set":_vm.get}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }