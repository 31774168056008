<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title>Промокоды (NEW)</v-card-title>
        <v-card-actions style="padding: 0 16px;">
          <v-btn @click="openPromoModal()">Создать</v-btn>
        </v-card-actions>
        <v-card-text>
          <div style="display: flex;gap: 10px">
            <v-checkbox label="Активные" v-model="filters.isActive"/>
            <v-text-field
                outlined
                v-model="filters.search"
                placeholder="Поиск по всем"
                @input="filters.isActive = false"
                label=""
                append-icon="mdi-magnify"/>
          </div>
          <v-data-table
              hover
              :items="filteredItems"
              :headers="promoHeader"
              item-key="id"
              :loading="loading"
              :search="filters.search"
              :footer-props="{'items-per-page-options':[-1]}"
              no-data-text="Промокодов нет"
              loading-text="Загрузка..."
              mobile-breakpoint="0"
          >
            <template v-slot:item="{item}">
              <tr style="cursor: pointer" @click="openPromoModal(item.id)">
                <td>{{ item.code }}</td>
                <td>{{ item.title }}</td>
                <td>{{
                    +item.discount_sum ? item.discount_sum + ' ' + $store.state.user.city.country.currency :
                        item.discount_percent ? item.discount_percent + '%' : ''
                  }}
                </td>
                <td>
                  <v-chip v-if="!item.isActive" class="ml-4" color="#eee">Не активен</v-chip>
                  <v-chip v-else class="ml-4" :color="'#c7ffc3'">Активен</v-chip>
                </td>
                <td> {{
                    `${item.date_from ? 'с ' + $root.dateToRus(item.date_from) : ''} ${item.date_to ? 'по ' + $root.dateToRus(item.date_to) : ''}`
                  }}
                </td>
                <td>
                  <template v-if="item.all_cities">Во всех</template>
                  <template v-else-if="item.cities.length">
                    <v-tooltip top max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <span  v-bind="attrs" v-on="on">
                           {{item.cities.map(c=>$store.state.getCityById(c).city).filter(c=>c).slice(0,3).join(', ')}} {{item.cities.length>3?`+${item.cities.length-3} шт.`:''}}
                        </span>
                      </template>
                      <span>
                        {{item.cities.map(c=>$store.state.getCityById(c).city).filter(c=>c).join(', ')}}
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-else-if="item.except_cities.length">
                    <v-tooltip top max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <span  v-bind="attrs" v-on="on">
                          Во всех, кроме:
                           {{item.except_cities.slice(0,3).map(c=>$store.state.getCityById(c).city).filter(c=>c).join(', ')}} {{item.except_cities.length>3?`+${item.except_cities.length-3} шт.`:''}}
                        </span>
                      </template>
                      <span>
                        {{item.except_cities.map(c=>$store.state.getCityById(c).city).filter(c=>c).join(', ')}}
                      </span>
                    </v-tooltip>
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <SetPromoModal ref="set_promo_modal" @on-set="get"/>
    </v-container>
  </v-app>
</template>

<script>
import SetPromoModal from "@/views/promo/SetPromoModal";

export default {
  name: "Promos2",
  components: {SetPromoModal},

  data() {
    return {
      items: [],
      loading: false,
      promoHeader: [
        {text: 'Код', value: 'code'},
        {text: 'Заголовок', value: 'title'},
        {text: 'Сумма/скидка', value: 'discount'},
        {text: 'Активен/нет', value: 'isActive'},
        {text: 'Период действия', value: 'date'},
        {text: 'Доступность', value: 'cities'}
      ],
      filters: {
        search: '',
        isActive: true
      }
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => this.filters.isActive ? item.isActive : item);
    }
  },
  methods: {
    get() {
      this.loading = true;
      this.$store.state.server.request('promonew/get', {}, (data) => {
        this.loading = false;
        this.items = data.response;
      }, (data) => {
        this.loading = false;
        this.$root.notify(data.error, 'error');
      })
    },
    openPromoModal(id) {
      this.$refs.set_promo_modal.open(id);
    }
  },
  mounted() {
    this.get();
    if (this.$route.query.promo) this.openPromoModal(this.$route.query.promo);
  }
}
</script>

<style scoped>

</style>
